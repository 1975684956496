var exports = {};
function _typeof(obj) {
  "@babel/helpers - typeof";

  return (exports = _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  }, exports.__esModule = true, exports["default"] = exports), _typeof(obj);
}
exports = _typeof, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;